<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      My Allocation
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>
              <v-card-text>
                <v-form ref="frmFilter">
                  <v-card-text class="px-0 py-0">
                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label
                          class="
                            text-md text-typo
                            font-weight-bolder
                            ms-1
                            mandatory
                          "
                          ><span class="red--text"><strong>* </strong></span
                          >Auction center</label
                        >

                        <v-autocomplete
                          hide-no-data
                          :items="auctionCenter"
                          item-text="auction_center_name"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.auctionCenter"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          height="46"
                          :rules="[(v) => !!v || 'Auction center is required']"
                          placeholder="Select auction center"
                          @change="clearOne"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.auction_center_name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Season</label
                        >

                        <v-autocomplete
                          hide-no-data
                          :items="season"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.season"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          outlined
                          height="46"
                          @change="filterSeasonSale()"
                          single-line
                          :rules="[(v) => !!v || 'Season is required']"
                          placeholder="Select Season"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Sale No</label
                        >

                        <v-autocomplete
                          hide-no-data
                          :items="seasonSale"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.seasonSale"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          @change="getList"
                          height="46"
                          :rules="[(v) => !!v || 'Sale No is required']"
                          placeholder="Select Sale No"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          >List Name</label
                        >
                        <v-autocomplete
                          hide-no-data
                          return-object
                          v-model="list"
                          :items="lists"
                          item-text="list_name"
                          color="rgba(0,0,0,.6)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-3
                          "
                          placeholder="Select a List"
                          :rules="[(v) => !!v || 'List is Required']"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions dense class="card-padding d-flex justify-end">
                    <v-row>
                      <v-col class="d-flex justify-end mx-5">
                        <v-btn
                          @click="close"
                          elevation="0"
                          :ripple="false"
                          height="46"
                          class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-secondary
                            bg-light
                            py-3
                            px-6
                            mt-7
                            mr-2
                          "
                          >Clear</v-btn
                        >

                        <v-btn
                          @click="searchData"
                          elevation="0"
                          :ripple="false"
                          height="46"
                          dark
                          class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-primary
                            bg-success
                            py-3
                            px-6
                            mt-7
                          "
                          >Search</v-btn
                        ></v-col
                      >
                    </v-row>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>

            <!-- AG Grid -->
            <Tasting
              :tableData="myAllocation"
              :validationData="validationData"
              v-if="myAllocation.length > 0"
              @submit="save"
            ></Tasting>
            <!--  -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import apiMaster from "../../../master/master-get-api";
import blend from "../../../../api/blend-api";
import { LABEL_TASTING_FEATURE } from "../../../../global-data/labels";
// import { AgGridVue } from "ag-grid-vue";
import Tasting from "../../../../components/TastingComponent/Tasting.vue";

export default {
  components: {
    // AgGridVue,
    Tasting,
  },
  data() {
    return {
      isDisable: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      overlay: false,

      auctionCenter: [],
      seasonSale: [],
      broker: [],
      teaType: [],
      category: [],
      grade: [],
      roles: [],

      editedItem: {
        auctionCenter: 0,
        seasonSale: 0,
        broker: [],
        season: 0,
        teaType: null,
        category: 0,
        grade: [],
        fromPrice: 0,
        toPrice: 0,
        role: null,
        list_name: 0,
      },
      defaultItem: {
        auctionCenter: 0,
        seasonSale: 0,
        broker: [],
        teaType: [],
        category: 0,
        grade: [],
        fromPrice: 0,
        toPrice: 0,
        role: null,
        list_name: 0,
        season: 0,
      },
      // ----
      leaf_appearance: 0,
      leaf_size: 0,
      color: 0,
      body: 0,
      taste: 0,
      //
      vendors: [],
      vendor: null,
      season: null,
      seasons: [],
      offer: null,
      offers: [],
      list: null,
      lists: [],
      myAllocation: [],
      prev_input: null,
      showTable: false,

      dialogue: false,

      tastingData: [
        {
          isDisable: false,
        },
      ],

      validationData: {},
      editedData: [],
      currentItem: 1,
      gridApi: null,
      columnApi: null,
    };
  },
  mounted() {
    this.initialize();
  },
  watch: {
    currentItem: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.focusInput(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    tabToNextCell(params) {
      const previousCell = params.previousCellPosition;
      const NextCell = params.nextCellPosition;
      let nextRowIndex = previousCell.rowIndex + 1;
      let renderedRowCount = params.api.getModel().getRowCount();
      console.log({
        rowIndex: nextRowIndex,
        column: previousCell.column,
        rowPinned: previousCell.rowPinned,
      });
      if (NextCell.column.colId != "special_marking") return NextCell;
      if (nextRowIndex >= renderedRowCount) {
        return null;
      } // returning null means don't navigate
      let firstCol = params.columnApi.getAllDisplayedColumns()[0];
      return {
        rowIndex: nextRowIndex,
        column: firstCol,
        rowPinned: previousCell.rowPinned,
      };
    },

    getIndex(item) {
      return this.myAllocation.indexOf(item);
    },

    getInput(id) {
      // this.$refs.first.$refs.input
      const input = this.$refs[`input-${id}`];
      return input;
    },
    changeFocusInput(event) {
      // this.prev_input = this.getInput(this.currentItem);

      const key_code = {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
      };

      let nextItem = this.currentItem;

      // if (event.keyCode == key_code.up) {
      //   nextItem -= 7;
      // } else if (event.keyCode == key_code.down) {
      //   nextItem += 7;
      // } else

      if (event.keyCode == key_code.left) {
        nextItem -= 1;
      } else if (event.keyCode == key_code.right) {
        nextItem += 1;
      }

      if (this.getInput(nextItem)) {
        this.currentItem = nextItem;
      }
    },
    focusInput(id) {
      const input = this.getInput(id);
      if (input) {
        input.focus();
        // const prev = this.getInput(id - 1);
        // const next = this.getInput(id + 1);
        // prev.blur();
        // next.blur();
      }
    },

    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    async initialize() {
      try {
        this.showTable = false;
        this.tastingData = [];
        this.myAllocation = [];
        this.overlay = true;
        this.auctionCenter = await apiMaster.getAuctionCenter();

        this.season = await apiMaster.getSeason();
        // this.broker = await apiMaster.getBrokers();

        // ## Getting Blend factors
        let result = await blend.getBlendfactor();

        const leaf_appearance = result.leaf_appearance.map((v) => {
          return v.value;
        });

        this.leaf_appearance = Math.max(...leaf_appearance);
        this.validationData.leaf_appearance = this.leaf_appearance;

        const body = result.body.map((v) => {
          return v.value;
        });
        this.body = Math.max(...body);
        this.validationData.body = this.body;

        const leaf_size = result.leaf_size.map((v) => {
          return v.value;
        });
        this.leaf_size = Math.max(...leaf_size);
        this.validationData.leaf_size = this.leaf_size;

        const color = result.color.map((v) => {
          return v.value;
        });
        this.color = Math.max(...color);
        this.validationData.color = this.color;

        const taste = result.taste.map((v) => {
          return v.value;
        });
        this.taste = Math.max(...taste);
        this.validationData.taste = this.taste;
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
      }
    },

    // isRequired(item) {
    //   if (
    //     item.body != null ||
    //     item.color != null ||
    //     item.leaf_appearance != null ||
    //     item.leaf_size != null ||
    //     item.taste != null
    //   ) {
    //     return false;
    //   }
    // },

    async getSeason() {
      try {
        this.overlay = true;
        this.seasons = await apiMaster.get(this.editedItem.auctionCenter);
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
      }
    },
    async filterSeasonSale() {
      this.overlay = true;
      this.editedItem.seasonSale = 0;
      this.editedItem.list_name = 0;
      this.seasonSale = await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
        this.editedItem.season,
        this.editedItem.auctionCenter
      );
      this.overlay = false;
    },

    async getList() {
      try {
        this.overlay = true;
        this.editedItem.list_name = 0;
        this.lists = await api.getList(this.editedItem.seasonSale);
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
      }
    },

    async searchData() {
      this.isDisable = [];
      //
      if (this.searchValidation()) {
        this.showTable = true;
        this.overlay = true;
        try {
          this.myAllocation = [];
          let result = await api.getMyAllocationByFilters({
            list_name: this.list.list_name,
            season_sale: this.editedItem.seasonSale,
            auction_center: this.editedItem.auctionCenter,
          });
          if (result) {
            if (result[0]?.child.length > 0) {
              this.myAllocation = result[0].child;

              this.myAllocation.forEach((el) => {
                if (el.item_name == null) {
                  this.isDisable.push({ isDisable: false });
                  el.isDisable = false;
                } else {
                  this.isDisable.push({ isDisable: true });
                  el.isDisable = true;
                }
              });

              this.tastingData = this.myAllocation;

              console.log("this.tastingData", this.tastingData);
            }
          }
        } catch (error) {
          this.showErrorAlert(error);
        } finally {
          this.overlay = false;
          this.scrollDown();
          // vm.$forceUpdate();
          //or in file components
          // this.$forceUpdate();
        }
      }
    },
    editDisable(item, index) {
      this.isDisable[index].isDisable = false;
    },

    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },

    close() {
      this.dialogue = false;

      this.editedItem = { ...this.defaultItem };
      this.list = null;
      this.lists = [];
      this.tastingData = [];
      this.myAllocation = [];
      this.showTable = false;

      this.$refs.frmFilter.resetValidation();
    },
    clearOne() {
      this.editedItem.season = 0;
      this.editedItem.seasonSale = 0;
      this.editedItem.list_name = 0;
      this.seasonSale = [];
    },

    async save(data) {
      // console.log("this.tastingData", data);

      try {
        this.overlay = true;
        this.dialogue = false;
        await api.saveData(data).then(() => {
          this.showSuccessAlert("Data Saved");
          this.$refs.frmFilter.reset();
          this.initialize();
        });
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 1000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 2500,
        icon: "error",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    edit(item) {
      this.editedData = [];
      this.editedData.push(item);
    },

    checkData(item) {
      item.isDisable = false;
    },
    frmvalidation() {
      return this.$refs.frmFilter.validate();
    },
  },
  computed: {
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
