import axios from "axios";

export default {
  async getVendors() {
    return await axios
      .get("vendors")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getSeason(vendor_id) {
    return await axios
      .get(`private-vendor-offer/vendor-offer-season-by-vendor-id/${vendor_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getOffers(vendor_id, season_id) {
    return await axios
      .get(`private-vendor-offer/get-offers-by-vendor-and-season?season_id=${season_id}&vendor_id=${vendor_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getList(season_sale_id) {
    return await axios
      .get(`auction-allocation/my-allocation/allocated-list/${season_sale_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getMyAllocation(listname) {
    return await axios
      .get(`auction-allocation/auction-allocation-by-list-name/${listname}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getMyAllocationByFilters(data) {
    const query = `season_sale=${data.season_sale}&list_name=${data.list_name}&auction_center=${data.auction_center}`;
    return await axios
      .get(`auction-allocation/my-allocation/list?${query}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(data) {
    return await axios
      .post("auction-allocation/update-tasting", { child: data })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
